import { useMemo } from 'react'
import { useLoginState } from 'state'

import { useUserCourses } from './nav.services'
import { NavbarProps, NavigationItem, NavigationType } from './nav.types'

export const useNavigation = ({
  navigation,
  type
}: {
  navigation: NavigationType
  type?: NavbarProps['type']
}) => {
  const { user } = useLoginState()
  const { data: activeCourses } = useUserCourses({ enabled: !!user })

  return useMemo(
    // If type is specified then show navigation based on type, but if not then show navigation based on user is logged state.
    () => {
      let navItems
      if (user && activeCourses) {
        navItems = navigation[type || 'product']

        // active bootcamp acess
        navItems = navItems.map((item) => {
          /**
           * format the bootcamp route
           */
          if (item.label === 'Bootcamp') {
            const newMenus: NavigationItem['menus'] = []
            for (const course of activeCourses) {
              if (course.course_id.length === 1) {
                newMenus.push({
                  label: course.course_name,
                  to: course.paid
                    ? `/lms?course_module_id=${course.course_id[0]}`
                    : course.redirect_url
                })
              }
            }

            return {
              ...item,
              menus: newMenus
            }
          } else {
            return item
          }
        })

        // for college dashboard dropdown
        if (user.type === 'admin' || user.type === 'college_admin') {
          if (user.colleges.length === 1) {
            navItems.push({
              label: 'College',
              to: `/college/${user.colleges[0].slug}`
            })
          } else if (user.colleges.length > 1) {
            navItems.push({
              label: 'College',
              to: '',
              menus: user.colleges.map((college) => ({
                label: college.name,
                to: `/college/${college.slug}`
              }))
            })
          }
        }
      } else {
        navItems = navigation[type || 'home']
      }
      return navItems
    },
    [activeCourses, navigation, type, user]
  )
}
