import { LmsQuestionSolveStatus } from 'features/LMS/lms.types'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { useAppSelector } from '../hooks'

type miniCourseSate = {
  courseQuestionDetails: {
    type: 'question' | 'blog' | null
    details: {
      id: number | null
      name: string
      slug: string
      status: LmsQuestionSolveStatus | null
    }
  }
  openSidebar: boolean
  currentDay: number
  currentDayCourseDataIndex: number
  progress: {
    totalQuestions: number
    solvedQuestions: number
  }
}

const initialState: miniCourseSate = {
  currentDay: 0,
  currentDayCourseDataIndex: 0,
  openSidebar: false,
  progress: {
    totalQuestions: 0,
    solvedQuestions: 0
  },
  courseQuestionDetails: {
    type: null,
    details: {
      id: null,
      name: '',
      slug: '',
      status: null
    }
  }
}

const miniCourseSlice = createSlice({
  name: 'MiniCourse',
  initialState,
  reducers: {
    setCurrentDay: (
      state,
      action: PayloadAction<miniCourseSate['currentDay']>
    ) => {
      state.currentDay = action.payload
    },
    setOpenSidebar: (
      state,
      action: PayloadAction<miniCourseSate['openSidebar']>
    ) => {
      state.openSidebar = action.payload
    },
    setCurrentDayCourseDataIndex: (
      state,
      action: PayloadAction<miniCourseSate['currentDayCourseDataIndex']>
    ) => {
      state.currentDayCourseDataIndex = action.payload
    },
    setProgress: (state, action: PayloadAction<miniCourseSate['progress']>) => {
      state.progress = action.payload
    },
    setCourseQuestionDetails: (
      state,
      action: PayloadAction<miniCourseSate['courseQuestionDetails']>
    ) => {
      state.courseQuestionDetails = action.payload
    }
  }
})

export default miniCourseSlice.reducer

export const {
  setOpenSidebar,
  setCourseQuestionDetails,
  setCurrentDay,
  setProgress,
  setCurrentDayCourseDataIndex
} = miniCourseSlice.actions

export const useMiniCourse = () => useAppSelector((state) => state.miniCourse)
