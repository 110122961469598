import { Link, UserImage } from 'components'
import { usePathname } from 'hooks'
import { OnClick } from 'interfaces'
import { useRouter } from 'next/router'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useLogoutMutation } from 'services/auth'
import { useLoginState } from 'state'

import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, ShareIcon, XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { tx } from '@twind/core'

import CollegeNavbar from './CollegeNavbar'
import { NavbarProps } from './nav.types'
import { CollegeNavigation, DevsnestNavigation } from './navdata'
import { useNavigation } from './useNavigation'

const Navbar = ({
  className,
  type,
  fullWidth,
  website,
  bgAndTextColor
}: NavbarProps) => {
  const { user } = useLoginState()
  const { isCollege } = usePathname()
  const { mutateAsync: logout } = useLogoutMutation(isCollege)

  const router = useRouter()
  const { ref } = router.query
  const referral_code = typeof ref === 'string' ? ref : ''
  const [referredCompany, setReferralCode] = useState(referral_code || '')
  const navigation = website ? CollegeNavigation : DevsnestNavigation

  useEffect(() => {
    if (referral_code) {
      setReferralCode(referral_code)
    }
  }, [referral_code])

  const signOut = useCallback(
    async (e: OnClick<HTMLAnchorElement>) => {
      e.preventDefault()
      await logout()
    },
    [logout]
  )

  const nav = useNavigation({ navigation, type })

  const profileDropdown = useMemo(() => {
    const options = [
      { label: 'Profile', to: `/u/${user?.username}` },
      { label: 'Dashboard', to: `/dashboard` },
      // { label: 'Referrals', to: '/school/referral' },
      { label: 'Sign Out', to: '#signOut', onClick: signOut }
    ]
    if (user?.type === 'admin' || user?.type === 'problem_setter') {
      options.unshift({ label: 'Admin', to: '/admin' })
    }

    return options
  }, [signOut, user?.type, user?.username])

  return !website ? (
    <Disclosure
      as="nav"
      className={tx(
        'shadow backdrop-filter backdrop-blur bg-zinc-900 bg-opacity-70 sticky top-0 w-full px-5 z-50',
        className
      )}>
      {({ open, close }) => (
        <>
          <div className={tx('py-1', !fullWidth && 'max-w-7xl mx-auto')}>
            <div className={'flex items-center justify-between h-14'}>
              <div className="flex items-center">
                <Link
                  prefetch={false}
                  href={user ? '/dashboard' : '/'}
                  className="flex-shrink-0 text-xl font-bold focus:outline-none focus:ring px-3 py-2 rounded-sm">
                  Devsnest
                </Link>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-center space-x-4">
                    {nav.map((item) =>
                      item.menus ? (
                        <Menu
                          key={item.label}
                          as="div"
                          className="relative inline-block text-left">
                          <div>
                            <Menu.Button className="flex items-center hover:bg-white hover:bg-opacity-10 text-white px-3 py-2 rounded-md text-base font-medium transition-colors focus:outline-none focus:ring">
                              <span>{item.label}</span>
                              <ChevronDownIcon className="w-4 h-4 text-white ml-2" />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95">
                            <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white focus:outline-none">
                              <div className="py-1">
                                {item.menus.map((item) => (
                                  <Menu.Item
                                    key={item.label}
                                    as="div"
                                    className="focus:ring">
                                    {({ active }) => (
                                      <Link
                                        prefetch={false}
                                        key={item.label}
                                        href={item.to}
                                        className={tx(
                                          'block relative bg-black  hover:bg-opacity-10 text-zinc-900 px-3 py-2 text-base transition-colors focus:outline-none',
                                          active
                                            ? 'bg-opacity-10'
                                            : 'bg-opacity-0'
                                        )}>
                                        {item.label}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      ) : (
                        <Link
                          key={item.label}
                          prefetch={false}
                          href={item.to}
                          activeClassName="bg-white bg-opacity-10"
                          className="relative hover:bg-white hover:bg-opacity-10 text-white px-3 py-2 rounded-md text-base font-medium transition-colors focus:outline-none focus:ring">
                          {item.label}
                          {item.new && (
                            <span className="top-0 transform translate-x-1/2 -translate-y-1/2 text-xs px-1 right-0 absolute rounded-md bg-indigo-600">
                              NEW
                            </span>
                          )}
                        </Link>
                      )
                    )}
                  </div>
                </div>
              </div>

              <div className="hidden md:block space-x-2">
                {user ? (
                  <div className="flex flex-row items-center">
                    <ShareJoining userName={user.username} coins={user.coins} />
                    <div className="ml-2 flex items-center">
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="max-w-xs rounded-md flex items-center hover:bg-white hover:bg-opacity-5 transition-colors px-2 py-1 text-sm focus:outline-none focus:ring">
                            <span className="sr-only">Open user menu</span>
                            <span className="mr-4 text-white text-base">
                              {user.name}
                            </span>
                            <UserImage
                              className="h-8 w-8 rounded-full"
                              src={user.image_url}
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white focus:outline-none">
                            {profileDropdown.map((item) => (
                              <Menu.Item key={item.label}>
                                {({ active }) => (
                                  <div
                                    key={item.label}
                                    className={tx(
                                      'cursor-pointer w-full',
                                      active ? 'bg-black bg-opacity-10' : ''
                                    )}>
                                    <Link
                                      prefetch={false}
                                      href={item.to}
                                      onClick={item.onClick}
                                      className="block px-4 py-2 text-sm text-zinc-800">
                                      {item.label}
                                      {item.label === 'Referrals' && (
                                        <span className="text-xs ml-4 px-1 text-white rounded-md bg-indigo-600">
                                          NEW
                                        </span>
                                      )}
                                    </Link>
                                  </div>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                ) : (
                  <>
                    <Link
                      href={
                        referredCompany
                          ? `/login?ref=${referredCompany}`
                          : '/login'
                      }
                      className="flex-1 text-center text-primary-dark shadow bg-white border-1 border-white hover:bg-zinc-100 px-5 py-2.5 rounded-md text-lg font-medium transition-colors">
                      Sign in
                    </Link>
                  </>
                )}
              </div>

              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-zinc-800 bg-opacity-50 inline-flex items-center justify-center p-2 rounded-md text-zinc-400 hover:text-white hover:bg-opacity-60 focus:outline-none">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          {/*
           * Mobile View Menu
           */}
          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {nav.map((item) =>
                item.menus ? (
                  <div key={item.label} className="m-2">
                    <div className="text-white text-base mb-1">
                      {item.label}
                    </div>

                    {item.menus.map((item) => (
                      <Link
                        prefetch={false}
                        key={item.label}
                        href={item.to}
                        onClick={() => close()}
                        activeClassName="bg-zinc-700 bg-opacity-60 text-white"
                        className="text-zinc-300 hover:bg-zinc-700 hover:bg-opacity-90 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                        {item.label}
                      </Link>
                    ))}
                  </div>
                ) : (
                  <Link
                    prefetch={false}
                    key={item.label}
                    href={item.to}
                    onClick={() => close()}
                    activeClassName="bg-zinc-700 bg-opacity-60 text-white"
                    className="flex items-center text-zinc-300 hover:bg-zinc-700 hover:bg-opacity-90 hover:text-white px-3 py-2 rounded-md text-base font-medium">
                    <div className="flex-1">{item.label}</div>
                    {item.new && (
                      <div className="text-sm px-1 rounded-sm bg-indigo-600">
                        NEW
                      </div>
                    )}
                  </Link>
                )
              )}
            </div>

            {user ? (
              <div className="pt-4 pb-3 border-t border-zinc-700">
                <div className="flex items-center px-5 justify-between">
                  <div className="flex flex-row items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user.image_url}
                        alt=""
                      />
                    </div>
                    <div>
                      <div className="text-base font-medium leading-none text-white">
                        {user.name}
                      </div>
                      <div className="text-sm font-medium leading-none text-zinc-400 mt-1">
                        {user.username}
                      </div>
                    </div>
                  </div>
                  <ShareJoining userName={user.username} coins={user.coins} />
                </div>

                <div className="mt-3 px-2 space-y-1">
                  {profileDropdown.map((item) => (
                    <div key={item.label} className="flex items-center">
                      <Link
                        prefetch={false}
                        href={item.to}
                        onClick={(e) => {
                          item.onClick && item?.onClick(e)
                          close()
                        }}
                        className="text-zinc-300 hover:bg-zinc-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                        {item.label}
                      </Link>
                      {item.label === 'Referrals' && (
                        <span className="text-xs px-1 rounded-md bg-indigo-600">
                          NEW
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex space-x-2 mx-2 py-3 border-t border-zinc-700">
                <Link
                  prefetch={false}
                  href={
                    referredCompany ? `/login?ref=${referredCompany}` : '/login'
                  }
                  onClick={() => close()}
                  className="flex-1 text-center text-zinc-200 shadow bg-transparent border-zinc-200 border-1  px-6 py-3 rounded-md text-lg font-semibold transition-colors">
                  Sign in
                </Link>
                <Link
                  prefetch={false}
                  href={referredCompany ? `/?ref=${referredCompany}` : '/'}
                  onClick={() => close()}
                  className="flex-1 text-center text-primary-dark shadow bg-white hover:bg-zinc-100  px-6 py-3 rounded-md text-lg font-semibold transition-colors">
                  Apply
                </Link>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  ) : (
    <CollegeNavbar
      user={user}
      className={className}
      type={type}
      fullWidth={fullWidth}
      website={website}
      bgAndTextColor={bgAndTextColor}
      nav={nav}
      profileDropdown={profileDropdown}
      referredCompany={referredCompany}
    />
  )
}

export default Navbar

const ShareJoining = ({
  userName,
  coins
}: {
  userName: string
  coins: number | null
}) => {
  const router = useRouter()
  return (
    <div
      className="py-1 px-2.5 bg-primary/20 hover:bg-primary/30 rounded-full cursor-pointer"
      onClick={() => router.push(`/enrolled-course/${userName}`)}>
      <div className={'gap-0.5 flex flex-row relative'}>
        <span className="text-primary">{coins ? coins / 10 : 0}</span>
        <ShareIcon className="w-5 inline-block text-primary" />
      </div>
    </div>
  )
}
