import { defineConfig } from '@twind/core'
import presetAutoprefix from '@twind/preset-autoprefix'
import presetLineClamp from '@twind/preset-line-clamp'
import presetTailwind from '@twind/preset-tailwind'
import presetTailwindForms from '@twind/preset-tailwind-forms'
import presetTypography from '@twind/preset-typography'

import customTypographyConfig from './custom-typography.config'

export default defineConfig({
  theme: {
    extend: {
      colors: {
        college: {
          primary: '#FBFCFE'
        },
        leaderboard: {
          one: '#906F16',
          two: '#4A5059',
          three: '#58392E'
        },
        profile: {
          devsnest: 'rgba(90, 90, 180, 1)',
          geeksforgeeks: 'rgba(5, 150, 105, 1)',
          leetcode: 'rgba(202, 138, 4, 1)',
          hackerrank: 'rgba(0, 151, 137, 1)'
        },
        primary: {
          light: '#A3A3FF',
          DEFAULT: '#8080FF',
          dark: '#5050B1',
          'very-light': '#C8C8FF',
          'very-dark': '#3C3C78'
        },
        secondary: {
          light: '#9290A2',
          DEFAULT: '#6E6B80',
          dark: '#454356'
        },
        accent: {
          DEFAULT: '#FF5226'
        },
        elevation: {
          1: '#030305',
          2: '#0D0D10',
          3: '#16161A',
          4: '#202026',
          5: '#2B2B32',
          6: '#404048'
        },
        content: {
          DEFAULT: '#FFFFFF',
          medium: '#C7C7D1',
          dark: '#A7A7BE',
          muted: '#6E6B80'
        },
        destructive: {
          DEFAULT: 'hsl(var(--destructive))',
          foreground: 'hsl(var(--destructive-foreground))'
        },
        muted: {
          DEFAULT: 'hsl(var(--muted))',
          foreground: 'hsl(var(--muted-foreground))'
        },
        // accent: {
        //   DEFAULT: 'hsl(var(--accent))',
        //   foreground: 'hsl(var(--accent-foreground))'
        // },
        input: 'hsl(var(--input))',
        ring: 'hsl(var(--ring))',
        background: 'hsl(var(--background))',
        foreground: 'hsl(var(--foreground))',
        popover: {
          DEFAULT: 'hsl(var(--popover))',
          foreground: 'hsl(var(--popover-foreground))'
        },
        card: {
          DEFAULT: 'hsl(var(--card))',
          foreground: 'hsl(var(--card-foreground))'
        }
      },
      fontFamily: {
        sans: ['IBM Plex Sans', 'sans-serif'],
        roboto: ['Roboto', 'sans-serif'],
        montserrat: ['Montserrat', 'sans-serif']
      },
      boxShadow: {
        shift2xl: '24px 24px 1px 1px rgba(39.0, 39.0, 42.0, 1.0)',
        'outer-1': '0px 0px 24px rgba(0, 0, 0, 0.4)'
      },
      screens: {
        xs: '320px'
      },
      minHeight: {
        '0': '0',
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
        full: '100%'
      },
      gridTemplateColumns: {
        fluid: 'repeat(auto-fit, minmax(18rem, 1fr))'
      },
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' }
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' }
        }
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out'
      },
      backgroundImage: {
        'mini-bootcamp-pattern':
          'linear-gradient(126deg, rgba(97, 97, 214, 0.20) -0.76%, rgba(128, 128, 128, 0.20) 75.69%)'
      }
    }
  },
  presets: [
    presetAutoprefix(),
    presetTailwind(),
    presetLineClamp(),
    presetTailwindForms(),
    presetTypography({
      extend: { ...customTypographyConfig }
    })
  ]
})
