import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { memo, useEffect } from 'react'

import { useSegment } from './hooks/useSegment'

const isProd = process.env.NODE_ENV === 'production'
const isNotServer = typeof window !== 'undefined'

export const GA_ID = process.env.NEXT_PUBLIC_GA_ID
  ? process.env.NEXT_PUBLIC_GA_ID
  : 'G-RY7ND05ZHV'
export const isProdAndNotServer = isProd && isNotServer
export const gtag = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`

/**
 * This component is only loaded on the client side and loads analytics and stuff
 */
const ClientServices = () => {
  const router = useRouter()

  useSegment()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (isProdAndNotServer) {
        window.gtag('config', GA_ID as string, {
          page_path: url
        })
      }
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}></Script>
      {/* 👇 gtag function definition. notice that we don't send page views at this point.  */}
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', '${GA_ID}');
        `}
      </Script>
    </>
  )
}

export default memo(ClientServices)
