import React from 'react'

import { tx } from '@twind/core'

const Loading = ({
  centerParent,
  className
}: {
  centerParent?: boolean
  className?: string
}) => {
  return (
    <div
      className={tx(
        'flex justify-center items-center align-middle',
        centerParent && 'w-full h-full m-auto',
        className
      )}>
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-zinc-100" />
    </div>
  )
}

export default Loading
