import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { useAppSelector } from '../hooks'
import { ActiveCourseState } from '../types'

const initialState: ActiveCourseState = {
  activeCourses: null
}

const activeCourseSlice = createSlice({
  name: 'activeCourseSlice',
  initialState,
  reducers: {
    setActiveCourse: (
      state,
      action: PayloadAction<ActiveCourseState['activeCourses']>
    ) => {
      state.activeCourses = action.payload
    }
  }
})

export default activeCourseSlice.reducer

export const { setActiveCourse } = activeCourseSlice.actions

export const useActiveCourses = () => {
  return useAppSelector((state) => state.activeCourses)
}
