import { logger } from 'utils'

export const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL
export const CLIENT_ID = process.env.NEXT_PUBLIC_DISCORD_CLIENT_ID
logger('api', `base url:`, API_BASE_URL)
export const SCORE_PER_TEST = 10
export const GITHUB_CLIENT_ID = process.env.NEXT_PUBLIC_GITHUB_CLIENT_ID

export const isServer = typeof window === 'undefined'
export const currentURL = isServer
  ? ''
  : `${window.location.protocol}//${window.location.host.split('www.').pop()}`

const discordRedirectUri = `${currentURL}/profile`
const githubScope = 'user:email%20read:user'

export const API_ENDPOINTS = {
  ADMIN_COLLEGE_FORMS: `api/v1/admin/college-form`,
  ADMIN_COLLEGE_PROFILE: `/api/v1/admin/college-profile`,
  ADMIN_FETCH_GROUP_DETAILS: `/api/v1/admin/groups/fetch_group_details`,
  ADMIN_LINK: `api/v1/admin/link`,
  ADMIN_PANEL_BACKEND_CHALLENGES: `/api/v1/admin/backend-challenge`,
  ADMIN_PANEL_SQL_CHALLENGES: `/api/v1/admin/sql-challenge`,
  ADMIN_PANEL_BATCH_LEADER_ASSIGN: `/api/v1/admin/groups/assign_batch_leader`,
  ADMIN_PANEL_CERTIFICATIONS: `api/v1/admin/certification`,
  ADMIN_PANEL_CHALLENGES: `/api/v1/admin/challenge`,
  ADMIN_PANEL_COMPANY: `/api/v1/admin/company`,
  ADMIN_PANEL_COURSE: `/api/v1/admin/course-module`,
  ADMIN_PANEL_COURSE_CURRICULUM: `api/v1/admin/course-curriculum`,
  ADMIN_PANEL_COURSE_CURRICULUM_ASSIGNMENTS: `api/v1/admin/assignment-question/add_assignment_questions`,
  ADMIN_PANEL_FRONTEND_QUESTION: `/api/v1/admin/frontend-question`,
  ADMIN_PANEL_FRONTNEND_BOOTCAMP: `/api/v1/admin/minibootcamp`,
  ADMIN_PANEL_FRONTNEND_CHALLENGES: `/api/v1/admin/frontend-challenge`,
  ADMIN_PANEL_WEB3_CHALLENGES: `/api/v1/admin/article/web3_questions`,
  ADMIN_PANEL_PROJECTS: `/api/v1/admin/projects`,
  ADMIN_PANEL_GROUPS: `api/v1/admin/groups`,
  ADMIN_PANEL_INTERNAL_FEEDBACK: `api/v1/admin/internal-feedback`,
  ADMIN_PANEL_USERS: `api/v1/admin/users`,
  ADMIN_ARTICLES: `/api/v1/admin/article`,
  ADMIN_SOURCING_JOBS: `/api/v1/admin/jobs`,
  ADMIN_SOURCING_ORGANIZATIONS: `/api/v1/admin/organization`,
  ADMIN_SOURCING_SKILL: `/api/v1/skill`,
  ALGO_EDITOR_QUESTIONS: `api/v1/challenge`,
  ALGO_LANGS: `/api/v1/language`,
  ARTICLES: `/api/v1/article`,
  ARTICLES_SUBMISSION: `api/v1/article/create_submission`,
  ASSIGN_COINS: `/api/v1/admin/reward`,
  BACKEND_CHALLENGES: `/api/v1/backend-challenge`,
  BACKEND_CHALLENGES_SUBMISSION: `/api/v1/be-submissions`,
  BATCH_LEADER_SHEET: `api/v1/batch-leader-sheet`,
  BOOTCAMP: `/api/v1/minibootcamp`,
  BOOTCAMP_MENU: `/api/v1/minibootcamp/menu`,
  BOOTCAMP_QUESTION: `/api/v1/frontend-questions`,
  BOOTCAMP_SUBMISSION: `/api/v1/minibootcamp-submissions`,
  CERTIFICATE: `api/v1/certification`,
  CHALLENGE_LEADERBOARD: `/api/v1/challenge/leaderboard`,
  COLLEGE: `/api/v1/college`,
  COLLEGE_FORMS: `/api/v1/college-form`,
  COLLEGE_INVITE: `/api/v1/college/invite`,
  COLLEGE_JOIN: `/api/v1/college/join`,
  COLLEGE_LOGIN: `/api/v1/users/college_login`,
  COLLEGE_STRUCTURE: `/api/v1/college/structure`,
  COLLEGE_STRUCTURE_SCHEMA: `/api/v1/college/structure_schema`,
  COLLEGE_STUDENTS: `/api/v1/college-students`,
  COMPANY: `/api/v1/company`,
  CONNECT_DISCORD: `/api/v1/users/connect_discord`,
  CONTENTS: `api/v1/contents`,
  CURRENT_USER: `api/v1/users/me`,
  DISCONNECT_DISCORD: `/api/v1/users/disconnect_user`,
  DISCORD_LOGIN_REDIRECT: `https://discordapp.com/api/oauth2/authorize?client_id=${CLIENT_ID}&scope=identify%20email&response_type=code&redirect_uri=${discordRedirectUri}`,
  DISCUSSION: `/api/v1/discussion`,
  DISCUSSION_UPVOTE: `/api/v1/upvote`,
  EMAIL_UNSUBSCRIBE: `/api/v1/users/unsubscribe`,
  FRONTEND_CHALLEGENS: `api/v1/frontend-challenge`,
  CHALLENGE_SOURCECODE_UPLOAD: `/api/v1/users/sourcecode_io`,
  FRONTEND_SUBMISSIONS: `api/v1/frontend-submissions`,
  FRONTEND_TEST_SUBMISSIONS: `api/v1/fe-submissions`,
  GROUP_MEMBER: `/api/v1/group-members`,
  GROUPS: `api/v1/groups`,
  HACKATHON: `api/v1/hackathon`,
  INTERNAL_FEEDBACK: `api/v1/internal-feedback`,
  LEADERBOARD: `api/v1/users/leaderboard`,
  LINK: `api/v1/link`,
  LMS_COURSE_CURRICULUM: `api/v1/course-curriculum`,
  LOGIN: `api/v1/users/login`,
  LOGOUT: `api/v1/users/logout`,
  NEXT_ALGO_QUESTION: `/api/v1/challenge/next_question`,
  ONBOARDING: `/api/v1/users/onboard`,
  PROJECT: `api/v1/projects`,
  REPORT: `api/v1/users/report`,
  SCRUMS: `api/v1/scrums`,
  SOURCING_JOB_APPLICATION: `/api/v1/job-applications`,
  SOURCING_JOBS: `/api/v1/jobs`,
  SUBMISSIONS: `api/v1/submissions`,
  SUBMIT_CODE: `/api/v1/algo-submission`,
  SUPPORT_MAIL: `/api/v1/admin/users/support_mail`,
  TEST_RUN_CODE: `api/v1/run-submission`,
  UPLOAD_FILE: `api/v1/users/upload_files`,
  Upload_I_Have_Enrolled_For_Course_Image: `api/v1/users/upload_i_have_enrolled_for_course_image`,
  USER: `api/v1/users`,
  USER_CERTIFICATE: `api/v1/users/[user_id]/certifications`,
  USER_DASHBOARD_DETAILS: `/api/v1/users/dashboard_details`,
  USER_INTEGRATION: `/api/v1/user-integration`,
  USER_PROJECT: `/api/v1/frontend-project`,
  USER_REFERRAL: `api/v1/referral`,
  WEEKLY_TODO: `/api/v1/weekly-todo`,
  CODING_ROOMS: `/api/v1/coding-rooms`,
  CODING_ROOMS_JOIN: `/api/v1/coding-rooms/join_room`,
  CODING_ROOMS_LEADERBOARD: `/api/v1/coding-rooms/leaderboard`,
  CODING_ROOMS_LEAVE_ROOM: `/api/v1/coding-rooms/leave_room`,
  CONNECT_GITHUB: `/api/v1/users/connect_github`,
  GITHUB_LOGIN_REDIRECT: `https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENT_ID}&scope=${githubScope}`,
  CODING_ROOMS_CURRENT_ROOM: `/api/v1/coding-rooms/current_user_room`,
  LMS_BOOTCAMP_PROGRESS: `/api/v1/bootcamp-progresses`,
  LMS_COMPLETE_DAY: '/api/v1/bootcamp-progresses/complete_day',
  EVENT_REGISTRATION: `/api/v1/event-registrations`,
  EDUCATION_EVENTS: `/api/v1/edu-events`,
  PAYMENTS_VERIFY: `/api/v1/orders/verify_payment`,
  USER_ACTIVE_COURSE: '/api/v1/product-prices/active_courses',
  PAYMENT_ORDER: `/api/v1/orders`,
  SQL_CHALLENGES: `api/v1/sql-challenge`,
  LANGUAGE_CHALLENGE_MAPPING: `/api/v1/admin/language-challenge-mapping`
}
