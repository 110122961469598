import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { useAppSelector } from '../hooks'

type lmsSate = {
  topicName: string
  questionDetails: {
    slug: string | null
    questionId: string | null
  }
  isLmsSidebarOpen: boolean
  lmsVideoUrl: string | null
  lmsTitle: string
  isVideoOnly: boolean
}

const initialState: lmsSate = {
  topicName: '',
  questionDetails: {
    slug: '',
    questionId: ''
  },
  isLmsSidebarOpen: true,
  lmsVideoUrl: null,
  lmsTitle: '',
  isVideoOnly: false
}

const lmsSlice = createSlice({
  name: 'LMS',
  initialState,
  reducers: {
    setIsVideoOnly: (state, action: PayloadAction<lmsSate['isVideoOnly']>) => {
      state.isVideoOnly = action.payload
    },
    setLmsTitle: (state, action: PayloadAction<lmsSate['lmsTitle']>) => {
      state.lmsTitle = action.payload
    },
    setLmsVideoUrl: (state, action: PayloadAction<lmsSate['lmsVideoUrl']>) => {
      state.lmsVideoUrl = action.payload
    },
    // set topic Name
    setTopicName: (state, action: PayloadAction<lmsSate['topicName']>) => {
      state.topicName = action.payload
    },
    // set Question details
    setQuestionDetails: (
      state,
      action: PayloadAction<lmsSate['questionDetails']>
    ) => {
      state.questionDetails = { ...action.payload }
    },
    // set LMS side bar
    setIsLmsSidebarOpen: (
      state,
      action: PayloadAction<lmsSate['isLmsSidebarOpen']>
    ) => {
      state.isLmsSidebarOpen = action.payload
    }
  }
})

export default lmsSlice.reducer

export const {
  setIsVideoOnly,
  setLmsTitle,
  setLmsVideoUrl,
  setTopicName,
  setQuestionDetails,
  setIsLmsSidebarOpen
} = lmsSlice.actions

export const useLMS = () => useAppSelector((state) => state.lms)
