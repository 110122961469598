import { Footer, Navbar, NavbarProps } from 'components'
import { useSessionStorage } from 'hooks'
import { HTMLDivProps } from 'interfaces'
import dynamic from 'next/dynamic'
import NextProgress from 'nextjs-progressbar'
import React, { useEffect } from 'react'
import { useLoginState } from 'state'
import { cn } from 'utils'

// import { ArrowRightIcon } from '@radix-ui/react-icons'
import { tx } from '@twind/core'

// const NotificationDynamic = dynamic(
//   () => import('components/Misc/Notification')
// )
const EmailVerificationNotificationDynamic = dynamic(
  () => import('components/Misc/EmailVerificationNotification')
)
// const InfoNotification = dynamic(
//   () => import('components/Misc/InfoNotification')
// )

const LayoutConfigs: Record<string, Record<string, string>> = {
  default: {
    bgColor: 'bg-zinc-900',
    textColor: 'text-white'
  },
  college: {
    bgColor: 'bg-[#FBFCFE]',
    textColor: 'text-black'
  }
}

export type LayoutProps = {
  children?: React.ReactNode | React.ReactNode[]
  hideFooter?: boolean
  hideNavbar?: boolean
  hideNotification?: boolean
  hideSchoolNotification?: boolean
  hideEmailVerificationNotification?: boolean
  pageClassName?: string
  navProps?: NavbarProps
  screenWidth?: boolean
  haveMaxWidth?: boolean
  showMarketingInfoNotification?: boolean
  /**
   * If height of this page should be equal to screen height
   */
  screenHeight?: boolean
  website?: string
} & HTMLDivProps

const Layout = ({
  children,
  hideFooter,
  hideNavbar,
  // hideNotification = true,
  // hideSchoolNotification = false,
  hideEmailVerificationNotification,
  className,
  pageClassName,
  navProps,
  screenHeight,
  screenWidth,
  website,
  haveMaxWidth = true,
  // showMarketingInfoNotification = false,
  ...props
}: LayoutProps) => {
  const loginState = useLoginState()

  const [isEmailVerify, setIsEmailVerify] = useSessionStorage(
    'email-verify-notification',
    true
  )

  useEffect(() => {
    if (loginState.isLoggedIn && loginState.user) {
      setIsEmailVerify(loginState.user.is_verified)
    }
  }, [loginState.isLoggedIn, loginState.user, setIsEmailVerify])

  const showEmailVerifyNoti =
    loginState.isLoggedIn &&
    !hideEmailVerificationNotification &&
    !isEmailVerify

  const bgAndTextColor = website
    ? `${LayoutConfigs[website].bgColor} ${LayoutConfigs[website].textColor}`
    : `${LayoutConfigs.default.bgColor} ${LayoutConfigs.default.textColor}`

  // const {
  //   isSchool,
  //   isBackend,
  //   isFrontend,
  //   isDSA,
  //   isLms,
  //   isCodingRoom,
  //   isAdmin,
  //   isWeb3Challenge
  // } = usePathname()

  // const avoidRouteForHideSchoolNotification = !(
  //   isSchool ||
  //   isBackend ||
  //   isFrontend ||
  //   isDSA ||
  //   isLms ||
  //   isCodingRoom ||
  //   isAdmin ||
  //   isWeb3Challenge
  // )

  // const isShowSchoolInfoNotification = hideSchoolNotification
  //   ? false
  //   : avoidRouteForHideSchoolNotification

  return (
    <div className={tx('font-sans', bgAndTextColor, className)} {...props}>
      <div
        className={tx(
          'flex flex-col min-h-screen',
          screenHeight && 'max-h-screen'
        )}>
        {/* {!hideNotification && <NotificationDynamic />} */}

        {!hideNavbar && (
          <Navbar
            {...navProps}
            website={website}
            bgAndTextColor={bgAndTextColor}
          />
        )}

        <NextProgress
          // Primary color
          color="#8080FF"
          startPosition={0.3}
          stopDelayMs={0}
          height={3}
          showOnShallow={true}
        />

        {showEmailVerifyNoti && (
          <EmailVerificationNotificationDynamic
            setIsEmailVerify={setIsEmailVerify}
          />
        )}

        {/* {showMarketingInfoNotification && ( */}
        {/*  <InfoNotification */}
        {/*    title={'Free Masterclass on Binary Search by Kshitiz Miglani'} */}
        {/*    showXIcon={false} */}
        {/*    linkButton={ */}
        {/*      <Link */}
        {/*        href={`https://airtribe.link/3ZbhvAG`} */}
        {/*        className="flex gap-1 underline justify-center items-center hover:scale-110"> */}
        {/*        Checkout <ArrowRightIcon className="h-4 w-4 pt-1" /> */}
        {/*      </Link> */}
        {/*    } */}
        {/*  /> */}
        {/* )} */}

        <div
          className={cn(
            'flex-1 flex flex-col w-full scrollbar',
            screenWidth
              ? 'max-w-screen'
              : haveMaxWidth
              ? 'max-w-7xl mx-auto'
              : '',
            pageClassName
          )}>
          {children}
        </div>
      </div>

      {!hideFooter && <Footer />}
    </div>
  )
}

export default Layout
