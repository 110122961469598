import defaultUser from 'assets/images/default-user.svg'
import React, { useState } from 'react'

type propTypes = {
  src: string
  className?: string
}

const UserImage: React.FC<propTypes> = ({ src, ...props }) => {
  const [error, setError] = useState(false)

  return (
    <img
      {...props}
      loading={'lazy'}
      src={error ? defaultUser.src : src}
      alt="user"
      onErrorCapture={() => setError(true)}
      onError={() => setError(true)}
    />
  )
}

export default UserImage
