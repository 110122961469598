import { dehydrate, QueryClient } from 'react-query'

export const createQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false
      }
    }
  })
}

export const dehydrateState = (queryClient: QueryClient) => {
  return { dehydratedState: dehydrate(queryClient) }
}
