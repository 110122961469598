import { useAnalytics } from 'hooks'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useLoginState } from 'state'

export const useSegment = () => {
  const router = useRouter()
  const { isLoggedIn, user } = useLoginState()
  const { page, identify } = useAnalytics()

  useEffect(() => {
    page()
  }, [router.pathname, page])

  useEffect(() => {
    if (isLoggedIn && user) {
      identify(user?.id?.toString(), {
        name: user?.name,
        username: user?.username,
        discord_username: user?.discord_username
      })
    }
  }, [isLoggedIn, user, identify])
}
