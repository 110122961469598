import dynamic from 'next/dynamic'
import React, { SVGProps } from 'react'

import { SandpackFiles } from '@codesandbox/sandpack-react'
import {
  aquaBlue,
  githubLight,
  monokaiPro,
  nightOwl,
  sandpackDark
} from '@codesandbox/sandpack-themes'
import { ChatBubbleIcon, ReaderIcon } from '@radix-ui/react-icons'

import { SandpackThemesType, TestCaseResultType } from '../FrontendIDE.types'

import {
  CSSIcon,
  HTMLIcon,
  JSIcon,
  JSONIcon,
  JSXIcon,
  TSIcon,
  TSXIcon
} from './assets/FileIcons'

const LearnSectionTabDynamic = dynamic(
  () => import('./components/DetailsSection/Tabs/LearnSectionTab')
)

const DiscussionForumDynamic = dynamic(
  () => import('components/Theme/DiscussionForum/DiscussionForum')
)

export const getCodeFromSandpackFile = (
  sandpackFile: SandpackFiles[keyof SandpackFiles]
) => {
  return typeof sandpackFile === 'string' ? sandpackFile : sandpackFile.code
}

export const filterSandpackFiles = (
  template_files: SandpackFiles,
  hidden_files: string[],
  protected_paths: string[]
) => {
  const templateFiles: SandpackFiles = {}
  Object.keys(template_files).forEach((fileName) => {
    if (fileName.endsWith('/')) return // to remove folders

    templateFiles[
      // add '/' if not present
      `${fileName.startsWith('/') ? '' : '/'}${fileName}`
    ] = {
      code: getCodeFromSandpackFile(template_files[fileName]),
      hidden: hidden_files.includes(fileName),
      readOnly: protected_paths.includes(fileName)
    }
  })
  return templateFiles
}

export const filterValidPaths = (paths: string[]) => {
  return paths.filter((path) => path.startsWith('/'))
}

export const removeCodeAttFromSandpack = (files: SandpackFiles) => {
  const updatedFiles = files
  Object.keys(files).forEach((fileName) => {
    updatedFiles[fileName] = getCodeFromSandpackFile(updatedFiles[fileName])
  })
  return updatedFiles
}

export const addCodeAttFromSandpack = (files: SandpackFiles) => {
  const updatedFiles = files
  Object.keys(files).forEach((fileName) => {
    const file = files[fileName]
    updatedFiles[fileName] = typeof file === 'string' ? { code: file } : file
  })
  return updatedFiles
}

export const removeHiddenFiles = (
  files: SandpackFiles,
  hiddenPaths: string[]
) => {
  const updatedFiles: SandpackFiles = {}
  Object.keys(files).forEach((fileName) => {
    if (!hiddenPaths.includes(fileName)) {
      updatedFiles[fileName] = files[fileName]
    }
  })
  return updatedFiles
}

export const isValidTemplate = (template: string | string[] | undefined) => {
  return (
    typeof template === 'string' &&
    [
      'angular',
      'react',
      'react-ts',
      'vanilla',
      'vanilla-ts',
      'vue',
      'vue3',
      'svelte'
    ].includes(template)
  )
}

/**
 * update theme
 * @param theme
 */
export const sandpackTheme = (theme: SandpackThemesType) => {
  if (theme === 'auto' || theme === 'light' || theme === 'dark') {
    return theme
  }
  switch (theme) {
    case 'sandpack-dark':
      return sandpackDark
    case 'aqua-blue':
      return aquaBlue
    case 'github-light':
      return githubLight
    case 'night-owl':
      return nightOwl
    default:
      return monokaiPro
  }
}

/**
 * Calculate score of test result
 */

export const calculateTestResult = (
  testCasesResponse: TestCaseResultType,
  challengePoint: number
) => {
  const total_test_cases = testCasesResponse.length
  let passed_test_cases = 0
  for (const test of testCasesResponse) {
    if (test.state === 'passed') {
      passed_test_cases++
    }
  }
  const score = Math.floor(
    challengePoint * (passed_test_cases / total_test_cases)
  )
  return {
    passed_test_cases,
    total_test_cases,
    score
  }
}

type ExtensionsWithIconsType = {
  [key: string]: (_props: SVGProps<SVGSVGElement>) => React.ReactElement
}

const ExtensionsWithIcons: ExtensionsWithIconsType = {
  '.html': HTMLIcon,
  '.css': CSSIcon,
  '.js': JSIcon,
  '.jsx': JSXIcon,
  '.ts': TSIcon,
  '.tsx': TSXIcon,
  '.json': JSONIcon
}

type PathInfoReturn = {
  path: string
  file: string
  name: string
  ext: string
  icon: (_props: SVGProps<SVGSVGElement>) => React.ReactElement
}

/**
 * Gives info about a file
 * ref: https://stackoverflow.com/a/71054530
 */
export function getFileInfo(s: string): PathInfoReturn {
  // @ts-ignore
  s = s.match(/(.*?[\\/:])?(([^\\/:]*?)(\.[^\\/.]+?)?)(?:[?#].*)?$/)
  return {
    path: s[1],
    file: s[2],
    name: s[3],
    ext: s[4],
    icon: ExtensionsWithIcons[s[4]]
  }
}

/**
 * filter user edited files for save the code
 * files = [index.html: {..}, test.js: {..}, script.js: {..}, package.json: {..}]
 * openPaths = [index.html, script.js]
 *
 * filterFiles = [index.html: {..}, script.js: {..}]
 */

export const filterFilesForSave = (
  files: SandpackFiles,
  openPaths: Array<string>
) => {
  const filterFiles: SandpackFiles = {}

  Object.keys(files).forEach((key) => {
    if (openPaths.includes(key)) {
      filterFiles[key] = files[key]
    }
  })
  return filterFiles
}

/**
 * get test result data from
 */

/**
 * get Tab for LMS
 */

export const LMSDataTabs = [
  {
    key: 'discussions',
    label: 'Discussions',
    icon: ChatBubbleIcon,
    component: DiscussionForumDynamic
  },
  {
    key: 'learn',
    label: 'Learn',
    icon: ReaderIcon,
    component: LearnSectionTabDynamic
  }
]
