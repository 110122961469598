import {
  ChallengeTemplate,
  ConsoleTab,
  DefaultEditorSettings,
  EditorSettings,
  LanguageOptions,
  LanguagesDetail
} from 'features/AlgoEditor'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type AlgoEditorState = {
  editorWidth: number
  settingsOpen: boolean
  consoleOpen: boolean
  loginModalOpen: boolean
  selectedConsoleTab: ConsoleTab
  code: string
  customTestCase: string
  isCustomTestCases: boolean
  settings: EditorSettings
  languages: Array<LanguagesDetail>
  isPolling: boolean
  nextAlgoQuestionSlug: string
  isNextChallenge: boolean
  challengeTemplate: ChallengeTemplate | null
}

const getSettings = () => {
  // in server just go with default settings
  if (typeof window === 'undefined') return DefaultEditorSettings

  try {
    const savedValue = localStorage.getItem('editor-settings')
    if (!savedValue) {
      localStorage.setItem(
        'editor-settings',
        JSON.stringify(DefaultEditorSettings)
      )
      return DefaultEditorSettings
    } else {
      const parsedValue = JSON.parse(savedValue)
      return parsedValue
    }
  } catch (err) {
    // if local storage not avail or anything
    return DefaultEditorSettings
  }
}

const initialState: AlgoEditorState = {
  editorWidth: 45,
  settingsOpen: false,
  consoleOpen: true,
  loginModalOpen: false,
  selectedConsoleTab: 'submission_status',
  settings: getSettings(),
  code: '',
  customTestCase: '',
  isCustomTestCases: false,
  languages: [{ ...LanguageOptions[1] }],
  isPolling: false,
  nextAlgoQuestionSlug: '',
  isNextChallenge: false,
  challengeTemplate: null
}

const algoEditorSlice = createSlice({
  name: 'algoEditor',
  initialState,
  reducers: {
    setChallengeTemplate: (
      state,
      action: PayloadAction<AlgoEditorState['challengeTemplate']>
    ) => {
      state.challengeTemplate = action.payload
    },
    setIsPolling: (state, action: PayloadAction<boolean>) => {
      state.isPolling = action.payload
    },
    setEditorWidth: (state, action: PayloadAction<number>) => {
      state.editorWidth = action.payload
    },
    updateSettings: (state, action: PayloadAction<Partial<EditorSettings>>) => {
      state.settings = { ...state.settings, ...action.payload }
    },
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload
    },
    setConsoleOpen: (state, action: PayloadAction<boolean>) => {
      state.consoleOpen = action.payload
    },
    setSettingsOpen: (state, action: PayloadAction<boolean>) => {
      state.settingsOpen = action.payload
    },
    setLoginModalOpen: (state, action: PayloadAction<boolean>) => {
      state.loginModalOpen = action.payload
    },
    setSelectedConsoleTab: (state, action: PayloadAction<ConsoleTab>) => {
      state.selectedConsoleTab = action.payload
    },
    setCustomTestCase: (state, action: PayloadAction<string>) => {
      state.customTestCase = action.payload
    },
    setIsCustomTestCases: (state, action: PayloadAction<boolean>) => {
      state.isCustomTestCases = action.payload
    },
    setLanguages: (state, action: PayloadAction<Array<LanguagesDetail>>) => {
      state.languages = action.payload
    },
    setNextAlgoQuestionSlug: (state, action: PayloadAction<string>) => {
      state.nextAlgoQuestionSlug = action.payload
    },
    setIsNextChallenge: (state, action: PayloadAction<boolean>) => {
      state.isNextChallenge = action.payload
    }
  }
})

export default algoEditorSlice.reducer

export const {
  updateSettings,
  setCode,
  setConsoleOpen,
  setSettingsOpen,
  setEditorWidth,
  setLoginModalOpen,
  setSelectedConsoleTab,
  setCustomTestCase,
  setIsCustomTestCases,
  setLanguages,
  setIsPolling,
  setNextAlgoQuestionSlug,
  setIsNextChallenge,
  setChallengeTemplate
} = algoEditorSlice.actions

export const useAlgoEditorState = () => {
  return useAppSelector((state) => state.algoEditor)
}

export const useAlgoEditorSettings = () => {
  const { settings } = useAlgoEditorState()
  const dispatch = useAppDispatch()

  const _updateSettings = useCallback(
    (_settings: Partial<EditorSettings>) => {
      dispatch(updateSettings(_settings))
      try {
        localStorage.setItem(
          'editor-settings',
          JSON.stringify({ ...settings, ..._settings })
        )
      } catch (err) {}
    },
    [dispatch, settings]
  )

  const _setSettingsOpen = useCallback(
    (_open: boolean) => dispatch(setSettingsOpen(_open)),
    [dispatch]
  )

  return useMemo(
    () => [settings, _updateSettings, _setSettingsOpen] as const,
    [settings, _updateSettings, _setSettingsOpen]
  )
}
