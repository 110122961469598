import { AppPage } from 'interfaces'
import Router from 'next/router'
import { useEffect, useMemo } from 'react'
import { logout, useAppDispatch, useLoginState } from 'state'

export const allowedCollegeUsers = ['admin', 'college_admin']

export const useCollegeAuth = (config: AppPage['auth']) => {
  const dispatch = useAppDispatch()
  const { isLoading, user } = useLoginState()

  const isDisallowed = useMemo(() => {
    if (config?.shouldBeLoggedIn && config?.isCollegeRedirect && !user) {
      return true
    }

    if (
      config?.allowedCollegeUsers &&
      (!user || (user.type && !config.allowedCollegeUsers.includes(user.type)))
    ) {
      return true
    }

    return false
  }, [config, user])

  useEffect(() => {
    if (isLoading) return

    if (config && isDisallowed && config.isCollegeRedirect) {
      dispatch(logout())
      Router.push(`/college/login`)
    }
  }, [config, dispatch, isDisallowed, isLoading, user])
}
