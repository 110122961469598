import { useAuth, useCollegeAuth } from 'hooks'
import { AppPage } from 'interfaces'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo } from 'react'
import { useLoginState } from 'state'

import { GoogleOAuthProvider } from '@react-oauth/google'

const TheProtector = ({
  children,
  config
}: {
  children: React.ReactElement
  config: AppPage['auth']
}) => {
  const { isLoading, user } = useLoginState()
  const router = useRouter()

  const isDisallowed = useMemo(() => {
    if (config?.shouldBeLoggedIn && !user) return true

    return !!(
      config?.userType &&
      (!user || (user.type && !config.userType.includes(user.type)))
    )
  }, [config, user])

  // For authenticating College Users
  useCollegeAuth(config)

  useEffect(() => {
    if (isLoading || !router.isReady) return

    if (config && isDisallowed && !config.isCollegeRedirect) {
      const redirectUrl = config.useCurrentUrlForRedirectUrl
        ? router.asPath
        : config.redirectUrl
      router.push(
        config.isDevsnestSchoolRedirect
          ? '/school/login'
          : config.redirectUrl || config.useCurrentUrlForRedirectUrl
          ? `/login?redirect=${redirectUrl}`
          : '/login'
      )
    }
  }, [config, isDisallowed, isLoading, router, user])

  if (isLoading || isDisallowed) {
    return (
      <div className="animate-spin h-24 w-24 rounded-full border-b border-white m-auto" />
    )
  }

  return children
}

export const AuthManager = ({
  children,
  config
}: {
  children: React.ReactElement
  config: AppPage['auth']
}) => {
  useAuth()

  return (
    <GoogleOAuthProvider
      clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || ''}>
      {config ? (
        <TheProtector config={config}>{children}</TheProtector>
      ) : (
        children
      )}
    </GoogleOAuthProvider>
  )
}
