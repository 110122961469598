import cookie from 'cookie'
import { User, UserUpdateParams } from 'features/User'
import { AppPage } from 'interfaces'
import { LoginParams } from 'interfaces/services'
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next'
import { useRouter } from 'next/router'
import cookies from 'react-cookies'
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query'
import {
  login,
  logout,
  setAuthorization,
  setLoginLoading,
  store,
  updateUser,
  useAppDispatch,
  useLoginState
} from 'state'

import { API_ENDPOINTS } from 'config/api'
import axios from 'config/axios'

export const fetchUser = async () => {
  const response = await axios.get(API_ENDPOINTS.CURRENT_USER)
  return {
    id: parseInt(response.data.data.id),
    ...response.data.data.attributes
  } as User
}

export const loadAuthSSR = async (
  ctx: GetServerSidePropsContext,
  queryClient: QueryClient,
  options: AppPage['auth'] = {}
) => {
  const { shouldBeLoggedIn, userType: allowedUserTypes, redirectUrl } = options
  const hasPermIfUnAuth = !shouldBeLoggedIn && !allowedUserTypes
  const redirectResult: GetServerSidePropsResult<{}> = {
    redirect: { destination: redirectUrl || '/login', statusCode: 307 },
    props: {}
  }

  const isServer = typeof window === 'undefined'
  if (!isServer) throw new Error('loadAuthSSR called on client side')

  // Server side auth
  const { authorization } = cookie.parse(ctx.req.headers.cookie || '')
  // If no cookie set then user is logged out
  if (!authorization) {
    return { auth: false, hasPermission: hasPermIfUnAuth, redirectResult }
  }

  // Set the auth token in state so axios request gets that
  store.dispatch(setAuthorization(authorization))
  // Fetch the user
  let user: User | null = null
  try {
    user = await fetchUser()
  } catch (err) {}

  if (!user) {
    return { auth: false, hasPermission: hasPermIfUnAuth, redirectResult }
  }

  await queryClient.prefetchQuery('auth/user', () => user)
  store.dispatch(login({ user, authorization }))
  store.dispatch(setLoginLoading(false))

  const hasPermission = allowedUserTypes
    ? allowedUserTypes.includes(user.type)
    : true

  return { auth: true, hasPermission, user, redirectResult }
}

/**
 * Used to get the latest user from server
 */
export const useUserQuery = (options?: {
  onSettled?: (_data?: User) => void
}) => {
  return useQuery<User, null>('auth/user', fetchUser, {
    onSettled: options && options.onSettled
  })
}

export const fetchUserUpdate = async (
  id: undefined | number,
  newUser: UserUpdateParams
) => {
  if (!id) throw new Error('No user id passed')

  const response = await axios.put(`${API_ENDPOINTS.USER}/${id}`, {
    data: {
      id: id.toString(),
      type: 'users',
      attributes: newUser
    }
  })
  return response.data.data.attributes
}

/**
 * Used to get the latest user from server
 */
export const useUserMutation = () => {
  const { user } = useLoginState()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  return useMutation(
    (newUser: UserUpdateParams) => fetchUserUpdate(user?.id, newUser),
    {
      onSuccess: (data) => {
        dispatch(updateUser(data))
        queryClient.invalidateQueries('College-student')
        queryClient.invalidateQueries('auth/user')

        if (user)
          queryClient.invalidateQueries(['user', { username: user.username }])
      }
    }
  )
}

export const fetchLogin = async (
  data: LoginParams,
  redirect: boolean = true
) => {
  const response = await axios.post(API_ENDPOINTS.LOGIN, data)
  return {
    user: response.data.data.attributes as User,
    authorization: response.headers.authorization,
    redirect: redirect ? data.redirect || true : false
  }
}

/**
 * Used to log in the user, updates local state and user query automatically
 */
export const useLoginMutation = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const queryClient = useQueryClient()

  return useMutation(fetchLogin, {
    onSuccess: (data) => {
      const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 21)
      cookies.save('authorization', data.authorization, { expires })
      dispatch(login(data))
      queryClient.invalidateQueries('auth/user')

      // if (data.user.colleges && data.user.colleges.length > 0) {
      //  data.user.colleges.length === 1
      //    ? router.push(`/college/${data.user.colleges[0].slug}`)
      //    : router.push('/college/all-college')
      // }

      if (data.redirect) {
        const redirect =
          typeof data.redirect === 'string' ? data.redirect : `/dashboard`
        router.push(redirect)
      }
    }
  })
}

export const fetchLogout = async () => {
  const response = await axios.delete(API_ENDPOINTS.LOGOUT)
  return response.data
}

/**
 * Used to log in the user, updates local state and user query automatically
 */
export const useLogoutMutation = (isCollege: boolean = false) => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const router = useRouter()

  return useMutation(fetchLogout, {
    onSuccess: () => {
      dispatch(logout())
      cookies.remove('authorization')
      return Promise.all([
        queryClient.invalidateQueries('auth/user'),
        !isCollege && router.push('/')
      ])
    }
  })
}

export const fetchDiscordConnectWithToken = async (botToken: string) => {
  const response = await axios.post(API_ENDPOINTS.CONNECT_DISCORD, {
    data: {
      attributes: {
        bot_token: botToken
      },
      type: 'users'
    }
  })
  return response.data
}

/**
 * Used to connect discord of current user using token from bot,
 * updates user query automatically
 */
export const useDiscordTokenConnectMutation = () => {
  const { user } = useLoginState()
  const queryClient = useQueryClient()

  return useMutation(fetchDiscordConnectWithToken, {
    onSuccess: () => {
      queryClient.invalidateQueries('auth/user')
      if (user)
        queryClient.invalidateQueries(['user', { username: user.username }])
    }
  })
}

export const fetchDiscordConnect = async (code: string) => {
  const response = await axios.post(API_ENDPOINTS.CONNECT_DISCORD, {
    code
  })
  return response.data
}

/**
 * Used to connect discord of current user using token from bot,
 * updates user query automatically
 */
export const useDiscordConnectMutation = () => {
  const { user } = useLoginState()
  const queryClient = useQueryClient()

  return useMutation(fetchDiscordConnect, {
    onSuccess: () => {
      queryClient.invalidateQueries('auth/user')
      if (user)
        queryClient.invalidateQueries(['user', { username: user.username }])
    }
  })
}

export const fetchUserByUsername = async (username: string) => {
  const response = await axios.get(
    `${API_ENDPOINTS.USER}/${username}/get_by_username`
  )
  return { ...response.data.data.attributes, id: response.data.data.id } as User
}

/**
 * Used to get the user details with a username, won't be fetched until username is present
 */
export const useUserByUsername = (username: string) => {
  return useQuery<User, null>(
    ['user', { username }],
    () => fetchUserByUsername(username),
    { enabled: !!username }
  )
}

/**
 * Sign Up Post Data Type
 */
export type SignupPostData = {
  name: string
  email: string
  password: string
  referral_code: string
  redirect?: string
  isCollegeStudent?: boolean
}

export const fetchCustomSignUp = async (
  data: SignupPostData,
  redirect: boolean = true
) => {
  const response = await axios.post(`${API_ENDPOINTS.USER}/register`, {
    name: data.name,
    email: data.email,
    password: data.password,
    password_confirmation: data.password,
    referral_code: data.referral_code,
    is_college_student: data.isCollegeStudent
  })

  return {
    user: response.data as User,
    authorization: response.headers.authorization,
    redirect: redirect ? data.redirect || true : false
  }
}

/**
 * Used to Custom Register User
 */
export const useCustomSignUpMutation = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const queryClient = useQueryClient()

  return useMutation(fetchCustomSignUp, {
    onSuccess: (data) => {
      const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 21)
      cookies.save('authorization', data.authorization, { expires })

      dispatch(login(data))

      queryClient.invalidateQueries('auth/user')

      if (data.redirect) {
        const redirect =
          typeof data.redirect === 'string' ? data.redirect : `/dashboard`
        router.push(redirect)
      }
    }
  })
}

/**
 * Log In Post Data Type
 */
export type LoginPostData = {
  email: string
  password: string
  remember_me: boolean
  login_method: string
  redirect?: string
}

export const fetchCustomLogIn = async (
  data: LoginPostData,
  redirect: boolean = true
) => {
  const response = await axios.post(`${API_ENDPOINTS.LOGIN}`, {
    email: data.email,
    password: data.password,
    login_method: data.login_method
  })

  return {
    user: response.data.data.attributes as User,
    authorization: response.headers.authorization,
    redirect: redirect ? data.redirect || true : false
  }
}

/**
 * Used to Custom Login User
 */
export const useCustomLogInMutation = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const queryClient = useQueryClient()

  return useMutation(fetchCustomLogIn, {
    onSuccess: (data) => {
      const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 21)
      cookies.save('authorization', data.authorization, { expires })

      dispatch(login(data))
      queryClient.invalidateQueries('auth/user')
      if (data.redirect) {
        const redirect =
          typeof data.redirect === 'string' ? data.redirect : `/dashboard`
        router.push(redirect)
      }
    }
  })
}

/**
 * Used to Initiate Password Reset
 */

export const fetchResetPasswordInitiator = async (email: string) => {
  const response = await axios.post(
    `${API_ENDPOINTS.USER}/reset_password_initiator`,
    {
      email
    }
  )

  return response?.data
}

export const useResetPasswordInitiatorMutation = () => {
  return useMutation(fetchResetPasswordInitiator)
}

/**
 * Used to Reset Password
 */

export const fetchResetPassword = async ({
  uid,
  password
}: {
  uid: string
  password: string
}) => {
  const response = await axios.post(`${API_ENDPOINTS.USER}/reset_password`, {
    uid,
    password
  })

  return response?.data
}

export const useResetPasswordMutation = () => {
  return useMutation(fetchResetPassword)
}

/**
 * Used to Initiate Verify Email
 */

export const fetchVerifyEmailInitiator = async () => {
  const response = await axios.post(
    `${API_ENDPOINTS.USER}/email_verification_initiator`
  )

  return response?.data
}

export const useVerifyEmailInitiatorMutation = () => {
  return useMutation(fetchVerifyEmailInitiator)
}

/**
 * Used to Verify Email
 */

export const fetchVerifyEmail = async (uid: string) => {
  const response = await axios.post(
    `${API_ENDPOINTS.USER}/email_verification`,
    {
      uid
    }
  )

  return response?.data
}

export const useVerifyEmailMutation = () => {
  const queryClient = useQueryClient()
  const router = useRouter()
  return useMutation(fetchVerifyEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries('auth/user')
      router.push('/profile')
    }
  })
}
