import Analytics from 'analytics'
import { logger } from 'utils'

// @ts-ignore
import segmentPlugin from '@analytics/segment'

const getPlugins = () => {
  const plugins = []

  try {
    const segmentKey = process.env.NEXT_PUBLIC_SEGMENT_KEY
    if (segmentKey) {
      plugins.push(
        segmentPlugin({
          writeKey: segmentKey
        })
      )
    }
  } catch (error) {
    logger('Analytics Error: ', error)
  }

  return plugins
}

export const analytics = Analytics({
  app: 'Devsnest',
  plugins: getPlugins()
})
