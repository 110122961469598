import algoEditorReducer from 'state/slices/algoEditorSlice'
import frontendIDEReducer from 'state/slices/frontendIDESlice'
import lmsReducer from 'state/slices/lmsSlice'
import loginReducer from 'state/slices/loginSlice'

import { configureStore } from '@reduxjs/toolkit'

import activeCourseSlice from './slices/activeCourseSlice'
import collegeUserSlice from './slices/collegeUserSlice'
import miniCourseSlice from './slices/miniCourseSlice'

export const store = configureStore({
  reducer: {
    login: loginReducer,
    algoEditor: algoEditorReducer,
    frontendIDE: frontendIDEReducer,
    lms: lmsReducer,
    college: collegeUserSlice,
    activeCourses: activeCourseSlice,
    miniCourse: miniCourseSlice
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
