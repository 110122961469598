import { Loading } from 'components'
import dynamic from 'next/dynamic'
import React from 'react'

import { ImportedDataState } from '@excalidraw/excalidraw/types/data/types'
import {
  ChatBubbleIcon,
  Pencil2Icon,
  ReaderIcon,
  RocketIcon
} from '@radix-ui/react-icons'

import {
  EditorSettings,
  EditorTheme,
  LanguagesDetail
} from './AlgoEditor.types'

const InstructionTabDynamic = dynamic(
  () => import('./components/tabs/InstructionTab')
)
const ExcalidrawTabDynamic = dynamic(
  () => import('./components/tabs/ExcalidrawTab'),
  {
    loading: () => (
      <div className="flex justify-center items-center text-center h-full w-full">
        <Loading />
      </div>
    )
  }
)

const SubmissionsTabDynamic = dynamic(
  () => import('./components/tabs/SubmissionsTab'),
  {
    loading: () => (
      <div className="flex justify-center items-center text-center h-full w-full">
        <Loading />
      </div>
    )
  }
)

const DiscussionTabDynamic = dynamic(
  () => import('components/Theme/DiscussionForum/DiscussionForum'),
  {
    loading: () => (
      <div className="flex justify-center items-center text-center h-full w-full">
        <Loading />
      </div>
    )
  }
)

export const AlgoEditorThemes: EditorTheme[] = [
  { label: 'VS Dark', id: 'vs-dark' },
  { label: 'Amy', id: 'amy' },
  { label: 'Blackboard', id: 'blackboard' },
  { label: 'Brilliance Dull', id: 'brilliance-dull' },
  { label: 'Clouds Midnight', id: 'clouds-midnight' },
  { label: 'Monokai', id: 'monokai' },
  { label: 'Oceanic Next', id: 'oceanic-next' },
  { label: 'Tomorrow-Night', id: 'tomorrow-night' }
]

export const AlgoEditorTabs = [
  {
    key: 'question',
    label: 'Instructions',
    icon: ReaderIcon,
    component: InstructionTabDynamic
  },
  {
    key: 'submissions',
    label: 'Submissions',
    icon: RocketIcon,
    component: SubmissionsTabDynamic
  },
  {
    key: 'excalidraw',
    label: 'White Board',
    icon: Pencil2Icon,
    component: ExcalidrawTabDynamic
  },
  {
    key: 'discussions',
    label: 'Discussions',
    icon: ChatBubbleIcon,
    component: DiscussionTabDynamic
  }
  // {
  //   key: 'leaderboard',
  //   label: 'LeaderBoard',
  //   icon: ActivityLogIcon,
  //   component: ChallengeLeaderBoard
  // }
]

export const LanguageOptions: LanguagesDetail[] = [
  {
    judge_zero_id: 50,
    id: 'c',
    name: 'C (GCC 9)',
    editorLang: 'c',
    language_id: '',
    defaultCode: `// Let's write some buggy code 😈`,
    language_description: ''
  },
  {
    judge_zero_id: 54,
    id: 'cpp',
    language_id: '',
    name: 'C++ (GCC 9)',
    editorLang: 'cpp',
    defaultCode: `// Let's write some buggy code 😈`,
    language_description: ''
  },
  {
    judge_zero_id: 62,
    id: 'java',
    language_id: '3',
    name: 'Java (OpenJDK 13)',
    editorLang: 'java',
    defaultCode: `// Let's write some buggy code 😈`,
    language_description: ''
  },
  {
    judge_zero_id: 63,
    id: 'javascript',
    name: 'JavaScript (Node.js 12)',
    editorLang: 'javascript',
    language_id: '',
    defaultCode: `// Let's write some buggy code 😈`,
    language_description: ''
  },
  {
    judge_zero_id: 71,
    id: 'python3',
    language_id: '1',
    name: 'python3',
    editorLang: 'python',
    defaultCode: "# Let's write some buggy code 😈",
    language_description: 'Python (3.8.1)'
  },
  {
    judge_zero_id: 74,
    id: 'typescript',
    name: 'TypeScript',
    editorLang: 'typescript',
    language_id: '',
    defaultCode: `// Let's write some buggy code 😈`,
    language_description: ''
  }
]

export const DefaultEditorSettings: EditorSettings = {
  minimap: false,
  wordWrap: true,
  fontSize: 16,
  theme: { label: 'VS Dark', id: 'vs-dark' },
  language: LanguageOptions[4]
}

export const AlgoDifficultyOptions = [
  {
    key: 'foundation',
    label: `Foundation`,
    color: 'text-blue-400',
    background: 'bg-blue-500'
  },
  {
    key: 'easy_level',
    label: `Easy`,
    color: 'text-green-400',
    background: 'bg-green-500'
  },
  {
    key: 'medium_level',
    label: `Medium`,
    color: 'text-yellow-400',
    background: 'bg-yellow-500'
  },
  {
    key: 'hard_level',
    label: `Hard`,
    color: 'text-red-400',
    background: 'bg-red-500'
  }
]

export const FrontendDifficultyOptions = [
  {
    key: 'html',
    label: 'HTML',
    color: 'text-green-400',
    background: 'bg-green-500'
  },
  {
    key: 'css',
    label: 'CSS',
    color: 'text-yellow-400',
    background: 'bg-yellow-500'
  },
  {
    key: 'javascript',
    label: 'JavaScript',
    color: 'text-blue-400',
    background: 'bg-blue-500'
  },
  {
    key: 'react',
    label: 'React',
    color: 'text-red-400',
    background: 'bg-red-500'
  }
]

export const topics = [
  {
    key: 'python_questions',
    label: 'Python'
  },
  {
    key: 'arrays',
    label: 'Arrays'
  },
  {
    key: 'strings',
    label: 'Strings'
  },
  {
    key: 'hashmap',
    label: 'Hashmap'
  },
  {
    key: 'tree',
    label: 'Tree'
  },
  {
    key: 'matrix',
    label: 'Matrix'
  },
  {
    key: 'graph',
    label: 'Graph'
  },
  {
    key: 'linkedlist',
    label: 'Linked list'
  },
  {
    key: 'stacks',
    label: 'Stacks'
  },
  {
    key: 'binarysearch',
    label: 'Binary Search'
  },
  {
    key: 'queue',
    label: 'Queues'
  },
  {
    key: 'heaps',
    label: 'Heaps'
  },
  {
    key: 'dynamicprogramming',
    label: 'Dynamic Programming'
  },
  {
    key: 'backtracking',
    label: 'Back Tracking'
  },
  {
    key: 'maths',
    label: 'Math'
  },
  {
    key: 'greedy',
    label: 'Greedy'
  }
]

export const initialData: ImportedDataState = {
  appState: { viewBackgroundColor: '#AFEEEE', currentItemFontFamily: 1 },
  scrollToContent: true
}

export const MOCK_DATA = [
  {
    src: '',
    title: 'Why my code is not working?',
    userName: 'Bob the Builder',
    createdAt: "12th March'21",
    description:
      'I have already tried the following ways, but noting is working... What wrong I am doing?',
    isClampDescription: true,
    clampDescriptionLineNumber: 2,
    onClick: () => {},
    onClickUser: () => {},
    containerClassName: 'my-3'
  },
  {
    src: '',
    title: '💯% Fast Java Solution, pls upvote 🔼',
    userName: 'Ben 10 wala FastTrack',
    createdAt: "10th March'21",
    description:
      'I have first used the fast I/O. Then I optimized the time complexity by removing the extra for loop and memoizing the min cost. Then I did this to achieve that in that manner by doing that. This is how I was able to achieve 💯 fast solution. I am at top in leaderboard. Please please please please please please please please please please please please please please upvote 🙏.',
    isClampDescription: true,
    clampDescriptionLineNumber: 2,
    onClick: () => {},
    onClickUser: () => {},
    containerClassName: 'my-3'
  },
  {
    src: '',
    title: 'Python Solution',
    userName: 'user12312312',
    createdAt: "9th March'21",
    description: 'My python solution.',
    isClampDescription: true,
    clampDescriptionLineNumber: 2,
    onClick: () => {},
    onClickUser: () => {},
    containerClassName: 'my-3'
  },
  {
    src: '',
    title: 'How to get height of div with JS?',
    userName: 'Bob the Builder',
    createdAt: "7th March'21",
    description:
      'I have a div in container center in a span of div. How to get the height of it in cm? Can someone please help me? I have already tried the following ways, but noting is working... What wrong I am doing? Should I leave web development? Thank you',
    isClampDescription: true,
    clampDescriptionLineNumber: 2,
    onClick: () => {},
    onClickUser: () => {},
    containerClassName: 'my-3'
  },
  {
    src: '',
    title: 'State is not updating in React, pls help!!!',
    userName: 'Spider Man',
    createdAt: "7th March'21",
    description: 'Help help help... 😢',
    isClampDescription: true,
    clampDescriptionLineNumber: 2,
    onClick: () => {},
    onClickUser: () => {},
    containerClassName: 'my-3'
  }
]

// const contentShareCourse =
//   'Hello Everyone!\n' +
//   'I have joined the 7 months Zero-cost Fullstack bootcamp at Devsnest\n' +
//   'Looking forward to building 10+ projects and learning from Industry experts of Top companies like Google, Amazon, Hackerrank'
//
// const contentTags = '#devsnest #fullstack #zerocost #bootcamp'
//
// const shareCourseUrl = 'https://devsnest.in/share'

// export const shareCourseData = [
//   {
//     href: WhatsappShareLink(shareCourseUrl, contentShareCourse),
//     icon: WhatsAppColorIcon
//   },
//   {
//     href: TwitterShareLink(shareCourseUrl, contentShareCourse, contentTags),
//     icon: TwitterColorIcon
//   },
//   {
//     href: LinkedinShareLink(shareCourseUrl),
//     icon: LinkedinColorIcon
//   },
//   {
//     href: FacebookShareLink(shareCourseUrl),
//     icon: FacebookColorIcon
//   }
// ]
