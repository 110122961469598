import { CollegeUser } from 'features/College'
import { useAppSelector } from 'state'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type CollegeUserState = {
  collegeUser: CollegeUser | null
}

const initialState: CollegeUserState = {
  collegeUser: null
}

const loginSlice = createSlice({
  name: 'collegeUserSlice',
  initialState,
  reducers: {
    updateCollegeUser: (state, action: PayloadAction<CollegeUser>) => {
      state.collegeUser = { ...state.collegeUser, ...action.payload }
    }
  }
})

export default loginSlice.reducer

export const { updateCollegeUser } = loginSlice.actions

/**
 * Use in the places where you know that user will always be logged in
 */
export const useCollegeUser = () => {
  const user = useAppSelector((state) => state.college)

  if (!user) throw new Error('No user is logged in')

  return user
}
