import { useQuery } from 'react-query'
import { setActiveCourse, useAppDispatch } from 'state'

import { API_ENDPOINTS } from 'config/api'
import axios from 'config/axios'

import { ActiveCourseItem, ActiveCourseResponse } from './nav.types'

const fetchUserCourses = async () => {
  const response = await axios.get(API_ENDPOINTS.USER_ACTIVE_COURSE)
  const { data } = (await response.data) as ActiveCourseResponse
  return data.attributes.data
}

export const useUserCourses = ({ enabled }: { enabled: boolean }) => {
  const dispatch = useAppDispatch()

  return useQuery<ActiveCourseItem[], null>(
    ['user-courses'],
    fetchUserCourses,
    {
      enabled,
      onSuccess: (data) => {
        dispatch(setActiveCourse(data))
      }
    }
  )
}
