import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const usePathname = () => {
  const navigation = useRouter()
  const [isPathname, setIsPathname] = useState({
    isHome: false,
    isProfile: false,
    isDashboard: false,
    isAdmin: false,
    isLms: false,
    isSchool: false,
    isFrontend: false,
    isBackend: false,
    isDSA: false,
    isWeb3Challenge: false,
    isCodingRoom: false,
    isCollege: false
  })

  useEffect(() => {
    const { pathname, query } = navigation
    const updatedIsPathname = {
      isHome: false,
      isProfile: false,
      isDashboard: false,
      isAdmin: false,
      isLms: false,
      isSchool: false,
      isFrontend: false,
      isBackend: false,
      isDSA: false,
      isWeb3Challenge: false,
      isCodingRoom: false,
      isCollege: false
    }

    const currentPath = pathname.split('/').slice(0, 2).join('/')

    const isQueryEmpty = Object.keys(query).length !== 0
    switch (currentPath) {
      case '/home':
        updatedIsPathname.isHome = true
        break
      case '/profile':
        updatedIsPathname.isProfile = true
        break
      case '/dashboard':
        updatedIsPathname.isDashboard = true
        break
      case '/admin':
        updatedIsPathname.isAdmin = true
        break
      case '/lms':
        updatedIsPathname.isLms = true
        break
      case '/school':
        updatedIsPathname.isSchool = true
        break
      case '/frontend-challenges':
        if (isQueryEmpty) {
          updatedIsPathname.isFrontend = true
        }
        break
      case '/backend-challenges':
        if (isQueryEmpty) {
          updatedIsPathname.isBackend = true
        }
        break
      case '/algo-challenges':
        if (isQueryEmpty) {
          updatedIsPathname.isDSA = true
        }
        break
      case '/coding-rooms':
        if (isQueryEmpty) {
          updatedIsPathname.isCodingRoom = true
        }
        break
      case '/web3-challenges':
        if (isQueryEmpty) {
          updatedIsPathname.isWeb3Challenge = true
        }
        break
      case '/college':
        updatedIsPathname.isCollege = true
        break
    }
    setIsPathname(updatedIsPathname)
  }, [navigation])

  return isPathname
}
