import { NavigationType } from './nav.types'

export const DevsnestNavigation: NavigationType = {
  home: [
    { label: 'Home', to: '/' },
    { label: 'Community', to: '/community' },
    { label: 'Curriculum', to: '/curriculum' },
    {
      label: 'Solve',
      to: '',
      new: true,
      menus: [
        {
          label: 'DSA',
          to: '/algo-challenges'
        },
        {
          label: 'Frontend',
          to: '/frontend-challenges'
        }
      ]
    },
    { label: 'School', to: '/school' }
  ],
  product: [
    {
      label: 'Bootcamp',
      to: '/lms'
    },
    {
      label: 'Solve',
      to: '',
      new: true,
      menus: [
        {
          label: 'DSA',
          to: '/algo-challenges'
        },
        {
          label: 'Frontend',
          to: '/frontend-challenges'
        },
        {
          label: 'Backend',
          to: '/backend-challenges'
        },
        { label: 'Coding Rooms', to: '/coding-rooms' }
      ]
    },
    { label: 'Groups', to: '/groups' },
    {
      label: 'Leaderboard',
      to: '',
      menus: [
        { label: 'DSA', to: '/dsa-leaderboard' },
        { label: 'Frontend', to: '/frontend-leaderboard' },
        { label: 'Hackathon', to: '/hackathon-leaderboard' }
      ]
    }
  ]
}

export const CollegeNavigation: NavigationType = {
  home: [
    // { label: 'School', to: '/school' }
    // { label: 'Community', to: '/community' },
    // { label: 'Curriculum', to: '/curriculum' }
  ],
  product: [
    // { label: 'School', to: '/school' }
    // { label: 'Community', to: '/community' },
    // { label: 'Curriculum', to: '/curriculum' }
  ]
}
