import cookies from 'react-cookies'
import { useUserQuery } from 'services/auth'
import { logout, setLoginLoading, updateUser, useAppDispatch } from 'state'

export const useAuth = () => {
  const dispatch = useAppDispatch()

  useUserQuery({
    onSettled: (data) => {
      if (data) {
        dispatch(updateUser(data))
      } else {
        cookies.remove('authorization')
        dispatch(logout())
      }
      dispatch(setLoginLoading(false))
    }
  })
}
