import {
  ChallengeTypeType,
  EditorSectionType,
  FrontendIDESettings,
  IDEType,
  LearnSectionType,
  TestCaseResultType
} from 'features/FE'
import {
  defaultSettings,
  filterSandpackFiles,
  filterValidPaths
} from 'features/FE/IDE'
import { useAppSelector } from 'state'

import { SandpackFiles } from '@codesandbox/sandpack-react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const getSettings = (key: string, defaultValue: any) => {
  // in server just go with default settings
  if (typeof window === 'undefined') return defaultValue

  try {
    const savedValue = localStorage.getItem(key)
    if (!savedValue) {
      localStorage.setItem(key, JSON.stringify(defaultValue))
      return defaultValue
    } else {
      const parsedValue = JSON.parse(savedValue)
      return parsedValue
    }
  } catch (err) {
    // if local storage not avail or anything
    return defaultValue
  }
}

type FrontendIDEState = {
  IDEType: IDEType
  challengeType: ChallengeTypeType
  learnDetails: LearnSectionType
  editorDetails: EditorSectionType
  files: SandpackFiles
  testCasesResponse: EditorSectionType['tasks'] | TestCaseResultType
  isTestCasesLoading: boolean
  settings: FrontendIDESettings
  extraStates: {
    showExplorer: boolean
    showTestCases: boolean
    showLearn: boolean
    showSidebar: boolean
    showSettings: boolean
  }
  learnStates: {
    showLearnDropDown: boolean
    showVideo: boolean
    showInstruction: boolean
    showFooter: boolean
    isVideoPlaying: boolean
    videoSeekTo: number
    videoPlayedSeconds: number
  }
}

const initialState: FrontendIDEState = {
  IDEType: 'playground',
  challengeType: 'local',
  learnDetails: {
    score: 0,
    bootcampID: '',
    courseName: '',
    topic: '',
    learnMarkdown: '',
    showFrontendIDE: true,
    videoURL: null,
    topicImageURL: null,
    tasksID: null,
    currentURL: '',
    nextURL: null,
    previousURL: null,
    currentLessonNumber: 0,
    totalLessonNumber: 0
  },
  editorDetails: {
    bootcampQuestionID: '',
    tasks: null,
    template: 'vanilla',
    activePath: '',
    openPaths: [],
    templateFiles: {},
    hiddenPaths: [],
    protectedPaths: [],
    showExplorer: false,
    lastSubmittedFiles: {},
    submissionID: null,
    closableTabs: false
  },
  files: {},
  testCasesResponse: null,
  isTestCasesLoading: false,
  settings: getSettings('frontend-ide-settings', defaultSettings),
  extraStates: {
    showExplorer: false,
    showTestCases: false,
    showLearn: true,
    showSidebar: false,
    showSettings: false
  },
  learnStates: {
    showLearnDropDown: true,
    showVideo: true,
    showInstruction: true,
    showFooter: true,
    isVideoPlaying: false,
    videoSeekTo: 0,
    videoPlayedSeconds: 0
  }
}

const frontendIDESlice = createSlice({
  name: 'frontendIDE',
  initialState,
  reducers: {
    setIsTestCasesLoading: (
      state,
      action: PayloadAction<Partial<FrontendIDEState['isTestCasesLoading']>>
    ) => {
      state.isTestCasesLoading = action.payload
    },
    setVideoPlayedSeconds: (
      state,
      action: PayloadAction<
        FrontendIDEState['learnStates']['videoPlayedSeconds']
      >
    ) => {
      state.learnStates.videoPlayedSeconds = action.payload
    },
    setVideoSeekTo: (
      state,
      action: PayloadAction<FrontendIDEState['learnStates']['videoSeekTo']>
    ) => {
      state.learnStates.videoSeekTo = action.payload
    },
    setVideoURL: (
      state,
      action: PayloadAction<FrontendIDEState['learnDetails']['videoURL']>
    ) => {
      state.learnDetails.videoURL = action.payload
    },
    // set challenges type
    setChallengeType: (state, action: PayloadAction<ChallengeTypeType>) => {
      state.challengeType = action.payload
    },
    // learnStatesReducers
    setIsVideoPlaying: (state, action: PayloadAction<boolean>) => {
      state.learnStates.isVideoPlaying = action.payload
    },
    setShowLearnDropDown: (state, action: PayloadAction<boolean>) => {
      state.learnStates.showLearnDropDown = action.payload
    },
    setShowVideo: (state, action: PayloadAction<boolean>) => {
      state.learnStates.showVideo = action.payload
    },
    setShowInstruction: (state, action: PayloadAction<boolean>) => {
      state.learnStates.showInstruction = action.payload
    },
    setShowFooter: (state, action: PayloadAction<boolean>) => {
      state.learnStates.showFooter = action.payload
    },
    // ClosableTabs
    setClosableTabs: (state, action: PayloadAction<boolean>) => {
      state.editorDetails.closableTabs = action.payload
    },
    // Type
    setIDEType: (state, action: PayloadAction<IDEType>) => {
      state.IDEType = action.payload
    },
    // ExtraStatesReducers
    setShowExplorer: (state, action: PayloadAction<boolean>) => {
      state.extraStates.showExplorer = action.payload
    },
    setShowLearn: (state, action: PayloadAction<boolean>) => {
      state.extraStates.showLearn = action.payload
    },
    setShowTestCases: (state, action: PayloadAction<boolean>) => {
      state.extraStates.showTestCases = action.payload
    },
    setShowSidebar: (state, action: PayloadAction<boolean>) => {
      state.extraStates.showSidebar = action.payload
    },
    setShowSettings: (state, action: PayloadAction<boolean>) => {
      state.extraStates.showSettings = action.payload
    },
    // MainDataReducers
    setLearnDetails: (state, action: PayloadAction<LearnSectionType>) => {
      state.learnDetails = action.payload
    },
    setEditorDetails: (
      state,
      action: PayloadAction<Partial<EditorSectionType>>
    ) => {
      state.editorDetails = {
        ...state.editorDetails,
        ...action.payload
      }

      const {
        templateFiles,
        lastSubmittedFiles,
        hiddenPaths,
        openPaths,
        protectedPaths
      } = action.payload

      if (openPaths) {
        state.editorDetails.openPaths = filterValidPaths(openPaths)
      }

      if (templateFiles) {
        state.editorDetails.templateFiles = filterSandpackFiles(
          templateFiles,
          hiddenPaths || [],
          protectedPaths || []
        )
      }

      if (lastSubmittedFiles) {
        state.editorDetails.lastSubmittedFiles = filterSandpackFiles(
          lastSubmittedFiles,
          hiddenPaths || [],
          protectedPaths || []
        )
      }
    },
    setSettings: (
      state,
      action: PayloadAction<Partial<FrontendIDEState['settings']>>
    ) => {
      try {
        localStorage.setItem(
          'frontend-ide-settings',
          JSON.stringify({ ...state.settings, ...action.payload })
        )
      } catch (err) {}
      state.settings = { ...state.settings, ...action.payload }
    },
    setTestCasesResponse: (
      state,
      action: PayloadAction<EditorSectionType['tasks']>
    ) => {
      state.testCasesResponse = action.payload
    },
    setFiles: (state) => {
      const { templateFiles, lastSubmittedFiles, hiddenPaths, protectedPaths } =
        state.editorDetails
      const files = filterSandpackFiles(
        templateFiles,
        hiddenPaths,
        protectedPaths
      )
      const filteredLastSubmittedFiles = filterSandpackFiles(
        lastSubmittedFiles,
        hiddenPaths,
        protectedPaths
      )

      if (lastSubmittedFiles) {
        Object.keys(lastSubmittedFiles).forEach((fileName) => {
          files[fileName] = filteredLastSubmittedFiles[fileName]
        })
      }

      state.files = files
    },
    resetFiles: (state) => {
      const { templateFiles, hiddenPaths, protectedPaths } = state.editorDetails
      state.files = filterSandpackFiles(
        templateFiles,
        hiddenPaths,
        protectedPaths
      )
    },
    resetState: () => initialState
  }
})

export default frontendIDESlice.reducer

export const {
  setIsTestCasesLoading,
  setVideoPlayedSeconds,
  setVideoSeekTo,
  setIsVideoPlaying,
  setVideoURL,
  setChallengeType,
  setShowLearnDropDown,
  setShowVideo,
  setShowFooter,
  setClosableTabs,
  setIDEType,
  setShowExplorer,
  setShowLearn,
  setShowTestCases,
  setShowSidebar,
  setShowSettings,
  setLearnDetails,
  setEditorDetails,
  setSettings,
  setTestCasesResponse,
  setFiles,
  resetFiles,
  resetState
} = frontendIDESlice.actions

export const useFrontendIDE = () => useAppSelector((state) => state.frontendIDE)
