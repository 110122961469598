import { ClientServices, HeadManager, Layout } from 'components'
import { AuthManager } from 'features/User'
import { AppProps } from 'interfaces'
import dynamic from 'next/dynamic'
import React, { useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { Hydrate as QueryClientHydrate, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { store } from 'state'

import { ErrorBoundary } from '@sentry/nextjs'
import install from '@twind/with-next/app'
import { createQueryClient } from 'config/query'
import twindConfig from 'config/twind.config'

import 'assets/styles/index.css'
import 'react-loading-skeleton/dist/skeleton.css'

const ErrorFallbackDynamic = dynamic(
  () => import('components/Ui/ErrorFallback')
)

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(createQueryClient)

  return (
    <ErrorBoundary
      fallback={({ error }) => <ErrorFallbackDynamic error={error} />}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <QueryClientHydrate state={pageProps.dehydratedState}>
            <HeadManager {...(Component.seo || {})} />

            <Layout {...(Component.layout || {})}>
              <AuthManager config={Component.auth}>
                <Component {...pageProps} />
              </AuthManager>
            </Layout>

            <ClientServices />

            <Toaster position="top-right" />
            <ReactQueryDevtools position="bottom-right" />
          </QueryClientHydrate>
        </QueryClientProvider>
      </Provider>
    </ErrorBoundary>
  )
}

export default install(twindConfig, MyApp)
