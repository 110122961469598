import { SandpackPredefinedTemplate } from '@codesandbox/sandpack-react'

import { FrontendIDESettings, SandpackThemesType } from '../FrontendIDE.types'

import AngularIcon from './assets/AngularIcon.svg'
import JsIcon from './assets/JsIcon.svg'
import ReactIcon from './assets/ReactIcon.svg'
import SvelteIcon from './assets/SvelteIcon.svg'
import TypescriptIcon from './assets/TypescriptIcon.svg'
import VueIcon from './assets/VueIcon.svg'

export const SandpackThemes: SandpackThemesType[] = [
  'light',
  'dark',
  'auto',
  'monokai-pro',
  'sandpack-dark',
  'night-owl',
  'aqua-blue',
  'github-light'
]

export const SandpackTemplatesObject: {
  id: SandpackPredefinedTemplate
  label: string
  image: any
}[] = [
  {
    id: 'react',
    label: 'React',
    image: ReactIcon
  },
  {
    id: 'react-ts',
    label: 'React Typescript',
    image: ReactIcon
  },
  {
    id: 'vanilla',
    label: 'Vanilla',
    image: JsIcon
  },
  {
    id: 'vanilla-ts',
    label: 'Vanilla Typescript',
    image: TypescriptIcon
  },
  {
    id: 'angular',
    label: 'Angular',
    image: AngularIcon
  },
  {
    id: 'vue',
    label: 'Vue',
    image: VueIcon
  },
  {
    id: 'vue3',
    label: 'Vue3',
    image: VueIcon
  },
  {
    id: 'svelte',
    label: 'Svelte',
    image: SvelteIcon
  }
]

export const defaultSettings: FrontendIDESettings = {
  theme: 'monokai-pro',
  wordWrap: true,
  showLineNumbers: true
}

export const getTemplateImageByID = (
  template: string | string[] | undefined
) => {
  switch (template) {
    case 'react':
    case 'react-ts':
      return ReactIcon

    case 'vanilla':
      return JsIcon

    case 'vanilla-ts':
      return TypescriptIcon

    case 'angular':
      return AngularIcon

    case 'vue':
    case 'vue3':
      return VueIcon

    case 'svelte':
      return SvelteIcon

    default:
      break
  }
}

export const getTemplateLabelByID = (
  template: string | string[] | undefined
) => {
  switch (template) {
    case 'react':
      return 'React'

    case 'react-ts':
      return 'React Typescript'

    case 'vanilla':
      return 'Vanilla'

    case 'vanilla-ts':
      return 'Vanilla Typescript'

    case 'angular':
      return 'Angular'

    case 'vue':
      return 'Vue'

    case 'vue3':
      return 'Vue3'

    case 'svelte':
      return 'Svelte'

    default:
      break
  }
}
