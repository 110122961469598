import { CSSNested } from '@twind/core'
import theme from '@twind/preset-tailwind/defaultTheme'

const round = (number: number): string =>
  number
    .toFixed(7)
    .replace(/(\.\d+?)0+$/, '$1')
    .replace(/\.0$/, '')

const rem = (px: number): string => `${round(px / 16)}rem`

const em = (px: number, base: number): string => `${round(px / base)}em`

const customTypographyConfig: CSSNested = {
  lineHeight: round(28 / 16),
  color: theme.colors.zinc['100'],
  maxWidth: '100% !important',
  p: {
    marginTop: em(20, 16),
    marginBottom: em(20, 16),
    color: theme.colors.zinc['100'],
    lineHeight: 1.75
  },
  [`[class~="lead"]`]: {
    fontSize: em(20, 16),
    lineHeight: round(32 / 20),
    marginTop: em(24, 20),
    marginBottom: em(24, 20)
  },
  blockquote: {
    marginTop: em(32, 20),
    marginBottom: em(32, 20),
    paddingLeft: em(20, 20),
    fontWeight: '500',
    fontStyle: 'italic',
    color: theme.colors.zinc['100'],
    borderLeftWidth: '0.25rem',
    borderLeftColor: theme.colors.zinc['100'],
    quotes: '"\\201C""\\201D""\\2018""\\2019"'
  },
  h1: {
    fontSize: em(36, 16),
    marginTop: '0',
    marginBottom: em(32, 36),
    lineHeight: round(40 / 36),
    color: theme.colors.zinc['100'],
    fontWeight: '800'
  },
  h2: {
    fontSize: em(24, 16),
    marginTop: em(48, 24),
    marginBottom: em(24, 24),
    lineHeight: round(32 / 24),
    color: theme.colors.zinc['100'],
    fontWeight: '700'
  },
  h3: {
    fontSize: em(20, 16),
    marginTop: em(32, 20),
    marginBottom: em(12, 20),
    lineHeight: round(32 / 20),
    color: theme.colors.zinc['100'],
    fontWeight: '600'
  },
  h4: {
    marginTop: em(24, 16),
    marginBottom: em(8, 16),
    lineHeight: round(24 / 16),
    color: theme.colors.zinc['100'],
    fontWeight: '600'
  },
  img: {
    marginTop: em(32, 16),
    marginBottom: em(32, 16)
  },
  video: {
    marginTop: em(32, 16),
    marginBottom: em(32, 16)
  },
  figure: {
    marginTop: em(32, 16),
    marginBottom: em(32, 16)
  },
  'figure > *': {
    marginTop: '0',
    marginBottom: '0'
  },
  'figure figcaption': {
    fontSize: em(14, 16),
    lineHeight: round(20 / 14),
    marginTop: em(12, 14),
    color: theme.colors.zinc['500']
  },
  code: {
    fontSize: em(14, 16),
    borderRadius: rem(4),
    color: theme.colors.zinc['100'],
    backgroundColor: theme.colors.gray['800'],
    fontWeight: '600',
    padding: '3px 7px'
  },
  'code::before': {
    content: '""'
  },
  'code::after': {
    content: '""'
  },
  'h2 code': {
    fontSize: em(21, 24)
  },
  'h3 code': {
    fontSize: em(18, 20)
  },
  pre: {
    fontSize: em(14, 16),
    lineHeight: round(24 / 14),
    marginTop: em(24, 14),
    marginBottom: em(24, 14),
    borderRadius: rem(6),
    paddingTop: em(12, 14),
    paddingRight: em(16, 14),
    paddingBottom: em(12, 14),
    paddingLeft: em(16, 14),
    color: theme.colors.zinc['200'],
    backgroundColor: theme.colors.zinc['800']
    // wordWrawhi: 'wrap'
  },
  ul: {
    marginTop: em(20, 16),
    marginBottom: em(20, 16),
    color: theme.colors.zinc['100']
  },
  li: {
    marginTop: em(8, 16),
    marginBottom: em(8, 16)
  },
  'li::marker': {
    color: theme.colors.zinc['100']
  },
  'ol > li': {
    // paddingLeft: em(28, 16),
    position: 'relative',
    counterIncrement: 'list-counter'
  },
  'ol > li::before': {
    left: '0',
    // content: 'counter(list-counter) "."',
    position: 'absolute',
    fontWeight: '400',
    color: theme.colors.zinc['500']
  },
  'ul > li': {
    // paddingLeft: em(28, 16),
    position: 'relative'
  },
  // 'ul > li::before': {
  //   width: em(6, 16),
  //   height: em(6, 16),
  //   top: `calc(${em(28 / 2, 16)} - ${em(3, 16)})`,
  //   left: em(4, 16),
  //   // content: '""',
  //   position: 'absolute',
  //   backgroundColor: theme.colors.zinc['600'],
  //   borderRadius: '50%'
  // },
  '> ul > li p': {
    marginTop: '0',
    marginBottom: '0'
  },
  '> ul > li > *:first-child': {
    marginTop: '0'
  },
  '> ul > li > *:last-child': {
    marginBottom: '0'
  },
  '> ol > li > *:first-child': {
    marginTop: '0'
  },
  '> ol > li > *:last-child': {
    marginBottom: '0'
  },
  'ul ul, ul ol, ol ul, ol ol': {
    marginTop: em(12, 16),
    marginBottom: em(12, 16)
  },
  hr: {
    marginTop: em(48, 16),
    marginBottom: em(48, 16),
    borderColor: theme.colors.zinc['100'],
    borderTopWidth: '1'
  },
  'hr + *': {
    marginTop: '0'
  },
  'h2 + *': {
    marginTop: '0'
  },
  'h3 + *': {
    marginTop: '0'
  },
  'h4 + *': {
    marginTop: '0'
  },
  table: {
    lineHeight: round(24 / 14),
    width: '90%',
    tableLayout: 'auto',
    textAlign: 'left',
    color: theme.colors.zinc['100'],
    marginTop: em(32, 16),
    marginBottom: em(32, 16)
  },
  thead: {
    fontWeight: '600',
    borderWidth: '1px',
    borderColor: theme.colors.zinc['700'],
    fontSize: em(18, 16)
  },
  'thead th': {
    paddingRight: em(8, 14),
    paddingBottom: em(8, 14),
    paddingLeft: em(8, 14),
    verticalAlign: 'bottom',
    borderWidth: '1px',
    borderColor: theme.colors.zinc['700'],
    color: theme.colors.zinc['100']
  },
  'tbody tr td': {
    borderWidth: '1px',
    borderColor: theme.colors.zinc['700']
  },
  'tbody tr:last-child': {
    borderBottomWidth: '0'
  },
  'tbody td': {
    paddingTop: em(8, 14),
    paddingRight: em(8, 14),
    paddingBottom: em(8, 14),
    paddingLeft: em(8, 14),
    verticalAlign: 'top',
    fontSize: em(16, 16)
  },
  'tbody td:last-child': {
    paddingRight: '0'
  },
  a: {
    color: theme.colors.zinc['100'],
    textDecoration: 'underline',
    fontWeight: '500'
  },
  strong: {
    color: theme.colors.zinc['100'],
    fontWeight: '600'
  },
  ol: {
    marginTop: em(20, 16),
    marginBottom: em(20, 16),
    counterReset: 'list-counter',
    color: theme.colors.zinc['100']
  },
  'blockquote p:first-of-type::before': {
    content: 'open-quote'
  },
  'blockquote p:last-of-type::after': {
    content: 'close-quote'
  },
  'a code': {
    color: theme.colors.zinc['100']
  },
  'pre code': {
    borderWidth: '0',
    borderRadius: '0',
    padding: '0',
    fontWeight: '400',
    color: 'inherit',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 'inherit',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  // @ts-ignore
  'code[class*=language-]': {
    wordBreak: 'break-word !important',
    whiteSpace: 'pre-wrap !important'
  },
  'pre code::before': {
    content: '""'
  },
  'pre code::after': {
    content: '""'
  }
}

export default customTypographyConfig
