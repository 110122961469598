import { Dispatch, SetStateAction, useEffect, useState } from 'react'

/*
 * usage: const [termsAccepted, setTermsAccepted] = useSessionStorage('terms', false);
 */

type SetValue<T> = Dispatch<SetStateAction<T>>

export function useSessionStorage<T>(
  key: string,
  defaultValue: T
): [T, SetValue<T>] {
  const getSessionStorageOrDefault = (): T => {
    try {
      if (typeof window === 'undefined') return defaultValue
      const stored = sessionStorage.getItem(key)
      return stored ? (parseJSON(stored) as T) : defaultValue
    } catch (error) {
      console.warn(`Error reading Session Storage key “${key}”:`, error)
      return defaultValue
    }
  }

  const [value, setValue] = useState<T>(getSessionStorageOrDefault())

  useEffect(() => {
    if (typeof window === 'undefined') return
    sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

/*
 * A wrapper for "JSON.parse()"" to support "undefined" value
 */
export function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch (error) {
    console.error('parsing error on', { value })
    return undefined
  }
}
